import { Button, Flex, Heading, Link } from '@madmeerkatfinance/uikit'
import { useAccount } from 'wagmi'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from '@madmeerkatfinance/localization'
// import Image from 'next/legacy/image'
// import { ChainId } from '@madmeerkatfinance/sdk'
// import { useActiveChainId } from 'hooks/useActiveChainId'
import styled, { keyframes } from 'styled-components'
// import { SlideSvgDark, SlideSvgLight } from './SlideSvg'
import { useGetFactoryVolume } from 'state/pools/hooks'
import formatLocalisedCompactNumber, { getBalanceNumber } from '@madmeerkatfinance/utils/formatBalance'
import { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useFarms, usePriceCakeUSD } from 'state/farms/hooks'
import { useMmfBurnt } from 'state/farmsV1/hooks'
import { useBurnedBalance, useTotalSupplyV1, useXmmfSupplyV1 } from 'hooks/useTotalSupply'
import { arbitrumTokens } from '@madmeerkatfinance/tokens'
import Stat from './MetricsSection/Stat'
import { CompositeImageProps } from './CompositeImage'

const flyingAnim = () => keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  to {
    transform: translate(0, 0px);
  }
`

const fading = () => keyframes`
  from {
    opacity: 0.9;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 0.9;
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
`

const InnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: -3px;
`

const BunnyWrapper = styled.div`
  width: 100%;
  animation: ${flyingAnim} 3.5s ease-in-out infinite;
  will-change: transform;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }
`

const StarsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  & :nth-child(2) {
    animation: ${fading} 2s ease-in-out infinite;
    animation-delay: 1s;
  }

  & :nth-child(3) {
    animation: ${fading} 5s ease-in-out infinite;
    animation-delay: 0.66s;
  }

  & :nth-child(4) {
    animation: ${fading} 2.5s ease-in-out infinite;
    animation-delay: 0.33s;
  }
`

const starsImage: CompositeImageProps = {
  path: '/images/home/lunar-bunny/',
  attributes: [
    { src: 'star-l', alt: '3D Star' },
    { src: 'star-r', alt: '3D Star' },
    { src: 'star-top-r', alt: '3D Star' },
  ],
}

const Hero = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  // const { chainId } = useActiveChainId()

  const { totalFactoryVolume } = useGetFactoryVolume()

  const volumeString =
    !Number.isNaN(totalFactoryVolume) || totalFactoryVolume ? formatLocalisedCompactNumber(totalFactoryVolume) : '-'

  const { data: farmData } = useFarms()

  const farmTVL = useMemo(() => {
    return farmData.reduce((prev, curr) => {
      let current =
        curr?.quoteTokenAmountTotal && curr.lpSymbol !== 'MMF'
          ? new BigNumber(curr?.quoteTokenAmountTotal).times(curr?.quoteTokenPriceBusd).times(2).toNumber()
          : 0
      // if (curr?.isStableSwap) {
      //   const totalLiquidity = new BigNumber(curr?.lpTotalInQuoteToken).times(curr?.quoteTokenPriceBusd)
      //   current = totalLiquidity.times(curr?.numTokens).times(curr?.tokenPriceVsQuote).toNumber()
      // }
      current = Number.isNaN(current) ? 0 : current
      return prev + current
    }, 0)
  }, [farmData])
  const tvl = farmTVL + 0 + 0
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '-'

  const recentMint = 0
  const amountOnDeployer = useMmfBurnt()
  // const MMFPrice = usePriceMMFBusd()
  const totalSupply = useTotalSupplyV1()
  const xMmfSupply = useXmmfSupplyV1()
  const burnedBalance = getBalanceNumber(useBurnedBalance(arbitrumTokens.mmf.address))
  const cakeSupply = totalSupply
    ? getBalanceNumber(totalSupply) -
      (burnedBalance + Number.parseFloat(amountOnDeployer) + recentMint) -
      getBalanceNumber(xMmfSupply)
    : 0
  const cakePriceBusd = usePriceCakeUSD()
  const mcap = cakePriceBusd.times(cakeSupply)

  const mcapString =
    !Number.isNaN(mcap.toNumber()) || mcap.toNumber() ? formatLocalisedCompactNumber(mcap.toNumber()) : '-'
  const cakeSupplyString = !Number.isNaN(cakeSupply) || cakeSupply ? formatLocalisedCompactNumber(cakeSupply) : '-'

  return (
    <>
      <Flex
        position="relative"
        flexDirection={['column-reverse', null, null, 'row']}
        alignItems={['flex-end', null, null, 'center']}
        justifyContent="center"
        // mt={[account ? '280px' : '50px', null, 0]}
        mb="20px"
        // id="homepage-hero"
      >
        <Flex
          height={['192px', null, null, '100%']}
          width={['192px', null, null, '100%']}
          maxWidth={['192px', null, null, '400px']}
          flex={[null, null, null, '1']}
          mt={['25px', null, null, '0']}
          mb={[null, null, null, '0']}
          mr={[null, null, null, '30px']}
          position="relative"
        >
          <BunnyWrapper>
            <img src="/madmeerkat/degen-logo.png" alt={t('Degen MMF Logo')} />
          </BunnyWrapper>
        </Flex>
        <Flex flex="1" flexDirection="column">
          <Heading scale="xxl" color="secondary">
            {t('MMF V3 Launched')}
          </Heading>
          <Heading scale="xxl" color="#03ffff" mb="24px">
            {t('Join the Madness.')}
          </Heading>
          <Heading scale="md" mb="24px">
            {t('Trade and earn crypto on the fastest growing DEX on Arbitrum.')}
          </Heading>
          <Flex>
            {!account && <ConnectWalletButton mr="8px" />}
            <Link href="/swap">
             <Button variant={!account ? 'secondary' : 'primary'}>{t('Trade Now')}</Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
      <Flex pb={['40px', null, null, null]} alignItems="center" justifyContent="center" marginX="auto">
        <Flex justifyContent="center" alignItems="center" flexDirection="column">
          <Flex
            flexDirection={['column', null, null, 'row']}
            flexWrap="wrap"
            width={['70%', '60%', '60%', '100%']}
            justifyContent="center"
          >
            <Stat
              // headingText={t('$%tvl%', { tvl: '###' })}
              headingText={t('$%tvl%', { tvl: tvlString })}
              bodyText={t('Total Value Locked')}
              // highlightColor="#03ffff"
              highlightColor="#fff"
            />

            <Stat
              // headingText={t('%mcap%', { mcap: '###' })}
              headingText={t('%mcap%', { mcap: mcapString })}
              bodyText={t('Circulating Market Cap')}
              // highlightColor="#03ffff"
              highlightColor="#fff"
            />

            <Stat
              // headingText={t('%supply%', { supply: '###' })}
              headingText={t('%supply%', { supply: cakeSupplyString })}
              bodyText={t('Circulating Supply')}
              // highlightColor="#03ffff"
              highlightColor="#fff"
            />

            <Stat
              // headingText={t('%volume%', { volume: '###' })}
              headingText={t('%volume%', { volume: volumeString })}
              bodyText={t('Total Volume Traded')}
              // highlightColor="#d2d22e"
              highlightColor="#fff"
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default Hero
