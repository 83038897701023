import { ReactNode } from 'react'
import styled from 'styled-components'

import PageHeader from './PageHeader'

const StyledPageHeader = styled(PageHeader)<{
  bgType: string
  isQuarterPage?: boolean
  isHalfPage?: boolean
  isDark?: boolean
}>`
  z-index: -3;
  position: relative;
  min-height: ${(x) => (x.isQuarterPage ? '25vh' : x.isHalfPage ? '60vh' : 'calc(100vh - 176px)')};
  background: ${(x) =>
    x.bgType === 'arbi'
      ? "url('/madmeerkat/degen-banner.png');"
      : x.bgType === 'white'
      ? '#ffffff;'
      : "url('/madmeerkat/meerkat.gif');"} 
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -1px;
  padding-top: 20px;
  .bg {
    position: absolute;
    max-width: 1200px;
    width: 100%;
    height: 400px;
    pointer-events: none;
    & > png {
      position: absolute;
      z-index: 0;
      right: 66px;
      bottom: 20px;
      pointer-events: none;
    }
  }
`

const StyledOverlay = styled.div<{
  overlay?: string
}>`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
  background: ${(x) =>
    x.overlay === 'white'
      ? 'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.75))'
      : 'linear-gradient(to left bottom, rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0.9))'};
`

const StyledPageHeaderWrap = ({
  children,
  bgType,
  overlay,
  isQuarterPage,
  isHalfPage,
}: {
  children?: ReactNode
  bgType?: string
  overlay?: string
  isQuarterPage?: boolean
  isHalfPage?: boolean
}) => (
  <StyledPageHeader bgType={bgType} isQuarterPage={isQuarterPage} isHalfPage={isHalfPage}>
    <StyledOverlay overlay={overlay} />
    {children}
  </StyledPageHeader>
)

export default StyledPageHeaderWrap
