import { arbitrumTokens } from '@madmeerkatfinance/tokens'
import { TranslateFunction } from '@madmeerkatfinance/localization'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: 'Bringing a Comprehensive Ecosystem to Arbitrum',
  bodyText: 'We begin our path to madness...',
  sections: [
    {
      title: 'Starting with a DEX',
      body: 'Efficiently swap tokens with our low trading fees of 0.17%. 0.1% of trading goes towards liquidity providers. 0.05% of fees goes towards building protocol-owned-liquidity for our native token on Arbitrum. ',
    },
    {
      title: 'Yield Optimizer',
      body: 'Automatically compounding your profits, both on MMF and on other protocols on Arbitrum, such as GMX.',
    },
    {
      title: 'Money Markets',
      body: 'Lending and borrowing are imperative components of DeFi. We will launch a money market with the intention to become one of the larger liquidity moats for borrowing and supplying of assets on Arbitrum.',
    },
  ],
  reverse: false,
  primaryButton: {
    to: `/swap?inputCurrency=${arbitrumTokens.usdc.address}&outputCurrency=${arbitrumTokens.mmf.address}`,
    text: 'Buy MMF',
    external: false,
  },
  secondaryButton: {
    to: 'https://mmfinance.gitbook.io/arbitrum/',
    text: 'Learn More',
    external: true,
  },
  images: {
    path: '/madmeerkat/nfts/home/',
    attributes: [
      { src: '1', alt: 'MMF token' },
      { src: '2', alt: 'SVN token' },
      { src: '3', alt: 'MMO token' },
      { src: '4', alt: 'MMT token' },
    ],
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('The First High Utility NFT on Arbitrum'),
  bodyText: t(
    'MMF NFTs that are not only astounding pieces of art. Use them to boost yields from farms and vaults. Become a MMF NFT holder, enjoy our play-to-earn features and be part of a seriously #MAD community.',
  ),
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: t('Explore'),
    external: false,
  },
  secondaryButton: {
    to: 'https://mmfinance.gitbook.io/docs/yield-farms',
    text: t('Learn'),
    external: true,
  },
  images: {
    path: '/madmeerkat/nfts/home-showcase/',
    attributes: [
      { src: '1', alt: 'Chart' },
      { src: '2', alt: 'Stock' },
      { src: '3', alt: 'MM' },
    ],
  },
})
