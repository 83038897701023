import styled from 'styled-components'
import { PageSection } from '@madmeerkatfinance/uikit'
import { useAccount } from 'wagmi'
import useTheme from 'hooks/useTheme'
import Container from 'components/Layout/Container'
import { useTranslation } from '@madmeerkatfinance/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import StyledPageHeaderWrap from 'components/PageHeader/StyledPageHeader'
import { ChainId } from '@madmeerkatfinance/sdk'
import Hero from './components/Hero'
import { swapSectionData, earnSectionData } from './components/SalesSection/data'
import MetricsSection from './components/MetricsSection'
import SalesSection from './components/SalesSection'
import WinSection from './components/WinSection'
import FarmsPoolsRow from './components/FarmsPoolsRow'
import Footer from './components/Footer'
import CakeDataRow from './components/CakeDataRow'
import { WedgeTopLeft, InnerWedgeWrapper, OuterWedgeWrapper, WedgeTopRight } from './components/WedgeSvgs'
import UserBanner from './components/UserBanner'
import MultipleBanner from './components/Banners/MultipleBanner'
import { SlideSvgLight } from './components/SlideSvg'

const StyledHeroSection = styled(PageSection)`
  padding-top: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 48px;
  }
`

const UserBannerWrapper = styled(Container)`
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding-left: 0px;
  padding-right: 0px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const InnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: -3px;
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()

  const HomeSectionContainerStyles = { margin: '0', width: '100%' }

  const { t } = useTranslation()

  return (
    <>
      <StyledPageHeaderWrap isHalfPage bgType="arbi">
        <div
          style={{
            height: '100%',
            width: '100%',
            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8))',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
        <PageSection
          innerProps={{ style: HomeSectionContainerStyles }}
          background="transparent"
          index={1}
          hasCurvedDivider={false}
          style={{
            minHeight: '1000px',
            margin: '0px',
            padding: '0px',
            display: 'flex',
            justifyContent: 'center',
            userSelect: 'none',
          }}
        >
          <Hero />
        </PageSection>
      </StyledPageHeaderWrap>

      <div style={{ position: 'relative' }}>
        <InnerWrapper>
          <SlideSvgLight width="100%" />
        </InnerWrapper>
      </div>

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background="white"
        index={2}
        hasCurvedDivider={false}
      >
        <SalesSection {...swapSectionData(t)} />
      </PageSection>

      <StyledPageHeaderWrap isHalfPage bgType="burrow">
        <OuterWedgeWrapper>
          <InnerWedgeWrapper width="150%" top fill="#fff">
            <WedgeTopRight />
          </InnerWedgeWrapper>
        </OuterWedgeWrapper>
        <div style={{ padding: '80px 0 40px 0' }}>
          <SalesSection headingColor="white" bodyColor="white" {...earnSectionData(t)} />
        </div>
      </StyledPageHeaderWrap>
    </>
  )
}

export default Home
