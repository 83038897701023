import { Svg, SvgProps } from '@madmeerkatfinance/uikit'

export const SlideSvgLight: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1515 128" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(0.000000, 0.689438)" fill="#FFFFFF" fillRule="nonzero">
          <path
            d="M497.139058,77.74686 C238.402612,77.74686 244.134036,-5.12325816 0,7.6365705 L0,127.310562 L1515,127.310562 L1515,7.6365705 C1428.42839,7.6365705 1263.41617,-6.20188936 1138.03053,3.37858285 C865.319452,24.2159034 684.698951,77.74686 497.139058,77.74686 Z"
            id="Path"
          />
        </g>
      </g>
    </Svg>
  )
}

export const SlideSvgDark: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1660 339" {...props}>
      <path
        d="M804 166.523C520.5 166.523 267.5 290.022 0 304V338.5H1660V0C1358.83 0 1104 166.523 804 166.523Z"
        fill="url(#paint0_linear_dark)"
      />
      <defs>
        <linearGradient id="paint0_linear_dark" x1="830" y1="83.5" x2="830" y2="338.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#08060B" stopOpacity="0.2" />
          <stop offset="0.545554" stopColor="#08060B" stopOpacity="0.5" />
          <stop offset="1" stopColor="#08060B" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export const SlideSvg: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 1660 339" {...props}>
      <path
        d="M804 166.523C520.5 166.523 267.5 290.022 0 304V338.5H1660V0C1358.83 0 1104 166.523 804 166.523Z"
        fill="url(#paint0_linear_dark)"
      />
      <defs>
        <linearGradient id="paint0_linear_dark" x1="830" y1="83.5" x2="830" y2="338.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#08060B" stopOpacity="0.2" />
          <stop offset="0.545554" stopColor="#08060B" stopOpacity="0.5" />
          <stop offset="1" stopColor="#08060B" />
        </linearGradient>
      </defs>
    </Svg>
  )
}
